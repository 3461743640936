import gsap from "gsap";
import { useEffect, useRef } from "react";
import styled from "styled-components";

const radius = 25;

const RippleSvg = styled.svg`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100px;
  width: 100px;
  margin: ${-radius}px 0 0 ${-radius}px;
`;

const RippleCircles = () => {
  const circle1 = useRef();
  const circle2 = useRef();
  useEffect(() => {
    gsap.set(circle1.current, {
      strokeOpacity: 0,
      transformOrigin: "center",
      scaleX: 0,
      scaleY: 0,
      translateX: radius,
      translateY: radius,
    });
    gsap.set(circle2.current, {
      strokeOpacity: 0,
      transformOrigin: "center",
      scaleX: 0,
      scaleY: 0,
      translateX: radius,
      translateY: radius,
    });

    const tl = gsap.timeline({
      repeat: -1,
    });
    tl.to(circle1.current, {
      strokeOpacity: 1,
      duration: 1,
    });
    tl.to(
      circle1.current,
      {
        strokeOpacity: 1,
        scaleX: 0.8,
        scaleY: 0.8,
        // repeat: -1,
        duration: 2,
        ease: "power1.inOut",
      },
      "-=1"
    );
    tl.to(circle1.current, {
      strokeOpacity: 0,
      duration: 0.5,
    });

    const tl2 = gsap.timeline({
      repeat: -1,
      delay: 0.1,
    });

    tl2.to(circle2.current, {
      strokeOpacity: 1,
      duration: 1.2,
    });
    tl2.to(
      circle2.current,
      {
        strokeOpacity: 1,
        scaleX: 0.8,
        scaleY: 0.8,
        duration: 2,
        ease: "power1.inOut",
      },
      "-=1"
    );
    tl2.to(circle2.current, {
      strokeOpacity: 0,
      duration: 0.8,
    });
  }, []);
  return (
    <RippleSvg xmlns="http://www.w3.org/2000/svg">
      <circle
        ref={circle1}
        cx="0"
        cy="0"
        r={radius}
        fill="none"
        stroke="#fff"
        strokeOpacity={0.1}
        strokeWidth={1.5}
      />
      <circle
        cx="0"
        cy="0"
        ref={circle2}
        r={radius}
        fill="none"
        stroke="#fff"
        strokeOpacity={0.1}
        strokeWidth={1.5}
        transform={`scale(1, 1) translate(${radius}, ${radius})`}
      />
    </RippleSvg>
  );
};
export default RippleCircles;
