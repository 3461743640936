import React from "react";
import styled from "styled-components";

const themeTextColor = "#fff";
// #b2954d
// #384675

const ContentWrapper = styled.div`
  padding: 24px;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  & > div:first-of-type {
    position: relative;
  }
`;

const Header = styled.div`
  text-align: center;
  padding: 16px;
  position: absolute;
  width: 100%;
  color: ${themeTextColor};
  display: flex;
  align-items: center;
  justify-content: center;
  & h3 {
    margin: 0;
    margin-left: 24px;
  }
  & span {
    display: inline-block;
  }
`;

const DomContentWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
`;
const ContentLayout = ({ header, children }) => (
  <DomContentWrapper>
    <Header>{header}</Header>
    <ContentWrapper>
      <div>{children}</div>
    </ContentWrapper>
  </DomContentWrapper>
);
export default ContentLayout;
