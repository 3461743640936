import React, { Fragment, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useCombinedScroller } from "../../combinedScroller";
import parse from "html-react-parser";
import gsap from "gsap";
import RippleCircles from "../../svgs/RippleCircles";
import { retractionAnimationDuration } from "../../timing";

const themeTextColor = "#fff";
// #b2954d
// #384675

const getDashOffset = (radius = 200) => {
  const off = [2, 5];
  const cir = Math.PI * radius;
  const number = Math.round(cir / 7);
  const offsets = new Array(number)
    .fill()
    .reduce((a, c) => {
      return [...a, ...off];
    }, [])
    .join(" ");
  return `${offsets} ${cir}`;
};

const Indicator = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 14px;
  height: 14px;
  margin: -7px 0 0 -7px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 50%;
  border: 3px solid ${themeTextColor};
  cursor: pointer;
  z-index: 10;
`;

const IndicatorWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 44px;
  height: 44px;
  margin: -22px 0 0 -22px;
  background-color: rgba(0, 0, 0, 0);
`;
const Slider = styled.div`
  position: absolute;
  left: 50%;
`;

const TitleWrapper = styled.div`
  position: absolute;
  z-index: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-family: grotesk-light;
  font-size: 36px;
  color: ${themeTextColor};
`;
const DragHint = styled.div`
  font-weight: 100;
  font-size: 12px;
  text-transform: uppercase;

  width: 120px;
  color: #ffffff;
  position: absolute;
  right: 20px;
  top: 14px;
`;
const stageRange = [0, 0.2, 0.4, 0.6, 0.8, 1];

const ProgressTrack = ({
  updateDragTarget,
  onStageChange,
  chapter,
  radius,
  transitionStarted,
  title,
}) => {
  const titleRef = useRef();
  const sliderRef = useRef();
  const pathRef = useRef();
  const targetRef = useRef();
  const dragRef = useRef();

  const { scroll, disable } = useCombinedScroller();
  const [transform, setTransform] = useState({ tx: 0, ty: 0, stage: 0 });
  const [atStart, setAtStart] = useState(true);

  useEffect(() => {
    setTransform(() => {
      const percentage = scroll.current / radius;
      const angle = percentage * Math.PI * 2 * 0.75;
      const closestStage = gsap.utils.snap(stageRange, percentage);
      const closestStageIndex = stageRange.indexOf(closestStage);

      return {
        tx: Math.sin(angle) * radius,
        ty: -Math.cos(angle) * radius + radius,
        stage: closestStage,
        diff: percentage - closestStage,
        stageIndex: closestStageIndex,
      };
    });
  }, [scroll, radius]);

  useEffect(() => {
    onStageChange(transform.stageIndex);
  }, [transform.stageIndex, onStageChange]);

  useEffect(() => {
    updateDragTarget(targetRef.current);
  }, [updateDragTarget]);

  useEffect(() => {
    gsap.set(titleRef.current, {
      skewY: -10,
      skewX: -5,
      transformOrigin: "0% 50%",
      autoAlpha: 0,
    });
  }, [radius]);

  useEffect(() => {
    if (scroll.current < 2 && !atStart) {
      setAtStart(true);
    } else if (scroll.current >= 2 && atStart) {
      setAtStart(false);
    }
  }, [scroll, atStart]);

  useEffect(() => {
    const path = pathRef.current;

    gsap.to(dragRef.current, {
      opacity: disable ? 0 : atStart ? 1 : 0,
      duration: 0.1,
    });

    gsap.to(titleRef.current, {
      skewX: atStart ? 0 : -5,
      skewY: atStart ? 0 : -5,
      rotation: atStart ? 0 : -2,
      autoAlpha: atStart ? 1 : 0,
    });

    if (disable) {
      const tl = gsap.timeline();
      tl.to(sliderRef.current, {
        duration: 0.5,
        autoAlpha: 0,
        y: -20,
        delay: 0.5,
      }).to(sliderRef.current, {
        duration: 0.5,
        y: 0,
        delay: 1,
      });
    }
    if (transitionStarted) {
      gsap.set(path, {
        strokeDasharray: `${getDashOffset(radius * Math.PI)} ${
          radius * Math.PI
        }`,
        strokeDashoffset: 0,
      });
      gsap.to(path, {
        strokeDashoffset: -radius * Math.PI,
        duration: 2,
      });
    } else if (atStart) {
      const tl = gsap.timeline();
      tl.add("start");
      const dashOffset = path.getAttribute("stroke-dashoffset");
      if (!dashOffset) {
        tl.set(path, {
          strokeDashoffset: -radius * Math.PI,
        });
      }

      let shouldAnimatie =
        parseFloat(path.getAttribute("stroke-dashoffset")) !== 0;
      if (shouldAnimatie) {
        tl.to(
          path,
          {
            strokeDashoffset: 0,
            duration: retractionAnimationDuration,
            delay: 0.5,
          },
          "start"
        );
      }
      tl.to(
        sliderRef.current,
        {
          autoAlpha: 1,
          y: 0,
          duration: 0.5,
          delay: 1,
        },
        "start"
      );
    }
  }, [atStart, disable, radius, transitionStarted]);

  return (
    <>
      <TitleWrapper>
        <Title ref={titleRef}>{parse(title || chapter.title)}</Title>
      </TitleWrapper>

      <Slider ref={sliderRef}>
        <IndicatorWrapper
          style={{
            transform: `matrix(1, 0, 0, 1, ${transform.tx}, ${transform.ty})`,
          }}
        >
          <Indicator ref={targetRef} />
          <div ref={dragRef}>
            {/* <DragHint>Start Scrolling</DragHint>
            <RippleCircles /> */}
          </div>
        </IndicatorWrapper>
      </Slider>
      <svg
        width={radius * 2}
        height={radius * 2}
        viewBox="0 0 198 198"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 99C1 153.124 44.8761 197 99 197C153.124 197 197 153.124 197 99C197 44.8761 153.124 1 99 1"
          //   stroke="#C6C6C6"
          // stroke="#999999"
          ref={pathRef}
          stroke="#fff"
          strokeDashoffset={-radius * 3}
          strokeDasharray={`${getDashOffset(radius * Math.PI)} ${
            radius * Math.PI
          }`}
          // strokeDasharray={getDashOffset(radius)}
        />
      </svg>
    </>
  );
};

export default ProgressTrack;
