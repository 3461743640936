import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { gsap } from "gsap";
import normalizeWheel from "normalize-wheel";

const CombinedScrollerContext = React.createContext();

export const getCursor = (e) => {
  if (
    e.type === "touchstart" ||
    e.type === "touchmove" ||
    e.type === "touchend" ||
    e.type === "touchcancel"
  ) {
    var evt = typeof e.originalEvent === "undefined" ? e : e.originalEvent;
    var touch = evt.touches[0] || evt.changedTouches[0];
    return {
      x: touch.pageX,
      y: touch.pageY,
    };
  } else if (
    e.type === "mousedown" ||
    e.type === "mouseup" ||
    e.type === "mousemove" ||
    e.type === "mouseover" ||
    e.type === "mouseout" ||
    e.type === "mouseenter" ||
    e.type === "mouseleave"
  ) {
    return {
      x: e.clientX,
      y: e.clientY,
    };
  }
};

export const withCombinedScroller = (Component, options = {}) => {
  const { direction = -1 } = options;
  return React.forwardRef((props, ref) => {
    const { scroll } = useCombinedScroller();
    const position = direction * scroll.current;
    return (
      <Component
        {...props}
        ref={ref}
        positionY={position}
        style={{
          transform: `translateY(${position}px)`,
          ...props.style,
        }}
      />
    );
  });
};
export const CombinedScrollerProvider = ({
  children,
  containerRef,
  options = {},
}) => {
  const {
    disableLimit = false,
    mouseSensitivity = 5,
    easing = 0.05,
    debug = true,
    wheelDirection = "y",
    dragDirection = "y",
    initialScrollPosition = 0,
    limit: fixedLimit,
    disable: defaultDisableState = true,
    wheelSpeed = 0.05,
    boundChecking,
  } = options;

  const ref = useRef();
  const [limit, setLimit] = React.useState(fixedLimit || 0);
  const [disable, setDisable] = React.useState(defaultDisableState);

  //  Dragging
  const [isDragging, setIsDragging] = React.useState(false);
  const [dragOn, setDragOn] = React.useState({ x: 0, y: 0 });

  const [scroll, setScroll] = React.useState({
    current: 0,
    target: initialScrollPosition,
    last: 0,
  });

  const onMouseWheel = React.useCallback(
    (event) => {
      const normalize = normalizeWheel(event);
      if (disable) return;
      setScroll((prev) => ({
        ...prev,
        target:
          prev.target +
          (wheelDirection === "y" ? normalize.pixelY : normalize.pixelX) *
            wheelSpeed,
      }));
    },
    [wheelDirection, disable, wheelSpeed]
  );

  const onUpdate = React.useCallback(() => {
    setScroll((prev) => {
      const clampedTarget = disableLimit
        ? prev.target
        : gsap.utils.clamp(0, limit, prev.target);
      const newCurrent = disableLimit
        ? gsap.utils.interpolate(prev.current, clampedTarget, easing)
        : gsap.utils.clamp(
            0,
            limit,
            gsap.utils.interpolate(prev.current, clampedTarget, easing)
          );
      const current =
        Math.abs(clampedTarget - newCurrent) < 0.001
          ? Math.round(newCurrent)
          : newCurrent;

      // Add logic checking for specific range
      if (boundChecking) {
        boundChecking.forEach(({ condition, callback }) => {
          if (condition({ current, limit })) {
            callback(current);
          }
        });
      }

      return {
        target: clampedTarget,
        current,
        last: prev.current,
      };
    });
    ref.current = requestAnimationFrame(onUpdate);
  }, [disableLimit, limit, easing, boundChecking]);

  //   Drag related
  const onMouseDown = React.useCallback(
    (e) => {
      const cursor = getCursor(e);
      if (isDragging || disable) return;
      setIsDragging(true);
      setDragOn({
        x: scroll.target - cursor.x * mouseSensitivity,
        y: scroll.target + cursor.y * mouseSensitivity,
      });
    },
    [isDragging, scroll, mouseSensitivity, disable]
  );

  const onMouseUp = React.useCallback(() => {
    if (!isDragging || disable) return;
    setIsDragging(false);
    setDragOn((prev) => ({
      x: 0,
      y: 0,
    }));
  }, [isDragging, disable]);

  const onMouseMove = React.useCallback(
    (e) => {
      const cursor = getCursor(e);
      if (!isDragging) return;
      const target =
        dragDirection === "y"
          ? dragOn.y - cursor.y * mouseSensitivity
          : dragOn.x + cursor.x * mouseSensitivity;

      setScroll((prev) => {
        return {
          ...prev,
          target,
        };
      });
    },
    [isDragging, dragOn, mouseSensitivity, dragDirection]
  );
  useEffect(() => {
    window.addEventListener("wheel", onMouseWheel);
    return () => {
      window.removeEventListener("wheel", onMouseWheel);
    };
  }, [onMouseWheel]);

  useEffect(() => {
    window.addEventListener("mousedown", onMouseDown);
    window.addEventListener("touchstart", onMouseDown);

    return () => {
      window.removeEventListener("mousedown", onMouseDown);

      window.removeEventListener("touchstart", onMouseDown);
    };
  }, [onMouseDown]);

  useEffect(() => {
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);

    window.addEventListener("touchmove", onMouseMove);
    window.addEventListener("touchend", onMouseUp);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);

      window.removeEventListener("touchmove", onMouseMove);
      window.removeEventListener("touchend", onMouseUp);
    };
  }, [onMouseMove, onMouseUp]);

  useEffect(() => {
    ref.current = requestAnimationFrame(onUpdate);
    return () => {
      cancelAnimationFrame(ref.current);
    };
  }, [onUpdate]);

  const onUpdateLimit = React.useCallback(() => {
    if (fixedLimit !== undefined) {
      setLimit(fixedLimit);
      return;
    }
    if (disableLimit) {
      setLimit(Infinity);
      return;
    }
    const { height, top } = containerRef.current.getBoundingClientRect();
    const offset = window.innerHeight - top;
    setLimit(height > offset ? height - offset : height);
  }, [containerRef, disableLimit, fixedLimit]);

  useEffect(() => {
    onUpdateLimit();
    window.addEventListener("resize", onUpdateLimit);
    return () => {
      window.removeEventListener("resize", onUpdateLimit);
    };
  }, [onUpdateLimit]);

  const value = useMemo(() => {
    return {
      scroll,
      limit,
      progress: scroll.current / limit,
      isDragging,
      scrollTo: setScroll,
      setDisable,
      disable,
    };
  }, [scroll, limit, isDragging, disable]);

  return (
    <CombinedScrollerContext.Provider value={value}>
      {debug && (
        <div style={{ position: "fixed", bottom: 24, left: 24, zIndex: 1000 }}>
          {scroll.current.toFixed(4)}
          <br />
          {scroll.target}
          <br />
          {limit}
          <br />
          Direction: {scroll.target < scroll.current ? "-1" : "1"}
        </div>
      )}
      {children}
    </CombinedScrollerContext.Provider>
  );
};

export const useCombinedScroller = () =>
  React.useContext(CombinedScrollerContext);
