const data = {
  stage: [
    {
      key: "01",
      name: "01",
      background: "#f0dd80",
      title: "ctrl n code",
      hotspots: {
        maxDistance: 5,
        tiltAngle: -0.35,
        dollyZoom: 1.5,
        items: [
          {
            id: 101,
            position: {
              x: -0.1,
              y: 0.96,
              z: 0.64,
            },
          },
          {
            id: 102,
            position: {
              x: 0.54,
              y: 1.61,
              z: 0.35,
            },
          },
          {
            id: 103,
            position: {
              x: 0.51,
              y: 1.27,
              z: -0.41,
            },
          },
          {
            id: 104,
            position: {
              x: -0.24,
              y: 0.92,
              z: -0.59,
            },
          },
          {
            id: 105,
            position: {
              x: -0.65,
              y: 1.19,
              z: -0.036,
            },
          },
        ],
      },
    },
  ],
};
export default data;
