import { Float, useGLTF } from "@react-three/drei";
import React, { forwardRef, useCallback, useEffect, useState } from "react";
import TriMeshedObject from "./TriMeshedObject";

const GLBModel = ({
  controls,
  progress,
  model,
  disableScrolling,
  onComplete,
}) => {
  const { src, displayScale, loadScale } = model;
  const { scene } = useGLTF(src);

  const [baseGeometry, setBaseGeometry] = useState([]);

  useEffect(() => {
    if (scene) {
      setBaseGeometry([]);
      scene.traverse((child) => {
        if (child.isMesh) {
          let o = child.clone();
          let newGeo = o.geometry.toNonIndexed();
          newGeo.scale(loadScale, loadScale, loadScale);
          //   Note: Must scale!! -> it updates geometry.position which is crucial in aCenter calculation
          setBaseGeometry((prev) => [...prev, newGeo]);
        }
      });
    }
  }, [scene]);

  const onLoaded = useCallback(() => {
    // console.log("loaded");
  }, []);

  return (
    <Float speed={2}>
      <group
        rotation={[0, progress * controls.speed * 5, 0]}
        scale={[displayScale, displayScale, displayScale]}
      >
        {baseGeometry.map((b) => (
          <TriMeshedObject
            key={b.uuid}
            geometryObject={b}
            controls={controls}
            animationProgress={progress}
            onLoaded={onLoaded}
          />
        ))}
      </group>
    </Float>
  );
};
export default forwardRef((props, ref) => <GLBModel {...props} ref={ref} />);
