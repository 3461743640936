import gsap from "gsap";

export const getCursor = (e) => {
  if (
    e.type === "touchstart" ||
    e.type === "touchmove" ||
    e.type === "touchend" ||
    e.type === "touchcancel"
  ) {
    var evt = typeof e.originalEvent === "undefined" ? e : e.originalEvent;
    var touch = evt.touches[0] || evt.changedTouches[0];
    return {
      x: touch.pageX,
      y: touch.pageY,
    };
  } else if (
    e.type === "mousedown" ||
    e.type === "mouseup" ||
    e.type === "mousemove" ||
    e.type === "mouseover" ||
    e.type === "mouseout" ||
    e.type === "mouseenter" ||
    e.type === "mouseleave"
  ) {
    return {
      x: e.clientX,
      y: e.clientY,
    };
  }
};

export const rotateCamera = (
  camera,
  onUpdate,
  offset = Math.PI * 2,
  duration = 5
) => {
  const from = camera.rotation.y;
  const target = from + offset;
  let i = { value: from };
  gsap.to(i, {
    value: target,
    duration,
    yoyo: true,
    onUpdate: () => {
      onUpdate(i.value);
      // moveCamera({ y: i.value }, false, null, null, undefined, {
      //   tiltAngle: 0,
      //   maxDistance: 5,
      //   dollyZoom: 1,
      // });
    },
  });
};

// const { rotateY } = useControls({
//   rotateY: { value: 0, min: -Math.PI, max: Math.PI },
// });
// useEffect(() => {
//   console.log(rotateY);
//   moveCameraClone({ y: rotateY }, false, null, null, undefined, {
//     tiltAngle: 0,
//     maxDistance: 5,
//     dollyZoom: 1,
//   });
// }, [rotateY]);
