import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { radiusByMedia } from "../constants";
import data from "../data";

const SceneContext = React.createContext();

const colors = [
  "#EDD834",

  "#339194",
  "#01C26E",

  "#E24C3F",

  "#78C0E0",

  "#ff8a00",
];

const models = [
  {
    src: "/models/logo.glb",
    displayScale: 0.65,
    loadScale: 0.5,
  },
];

const getStageSize = () => {
  let newSize = window.innerWidth / window.innerHeight;
  if (newSize < 1) {
    newSize = 1.5;
  }
  return newSize;
};

export const SceneProvider = ({ children }) => {
  const { id = 0 } = useParams();

  const chapter = useMemo(() => data.stage[id], [id]);

  const model = useMemo(() => models[id], [id]);

  const [transitionStarted, setTransitionStarted] = useState(false);
  const [disableInteraction, setDisableInteraction] = useState(false);

  const [atLowerRange, setAtLowerRange] = useState(false);
  const [atHigherRange, setAtHighRange] = useState(false);

  const [animationProgress, setAnimationProgress] = useState({ value: 0 });

  const [config, setConfig] = useState({
    darkMode: true,
    stageRadius: getStageSize(),
    progressRadius: window.matchMedia("(min-width: 768px)").matches
      ? radiusByMedia.desktop
      : radiusByMedia.mobile,
  });

  const [background, setBackground] = useState(colors[0]);

  const sceneConfig = useMemo(() => {
    const backgroundColor = config.darkMode ? "#111" : background;
    const ambientLightOp = colors;
    // .filter((p) => p !== backgroundColor);

    const newColor =
      ambientLightOp[Math.floor(Math.random() * ambientLightOp.length)];

    return {
      ...config,
      background: backgroundColor,
      spotLightIntensity: config.darkMode ? 10 : 2,
      spotlightSetting: config.darkMode
        ? {
            angle: Math.PI / 5,
          }
        : { angle: Math.PI / 7 },
      spotLightColor: "#ffffff",
      floorColor: config.darkMode ? "#111" : "#fff",
      ambientLight: "#fff",
    };
  }, [config, background]);

  const [activeStage, setActiveStage] = useState(0);

  // [Todo] Handle progress radius update on window resize
  useEffect(() => {
    const resize = () => {
      setConfig((prev) => ({
        ...prev,
        stageRadius: getStageSize(),
      }));
    };
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, []);
  // useEffect(() => {
  //   console.log("scene provider mounted");
  //   return () => {
  //     console.log("scene provider unmounted");
  //   };

  const goToNext = useCallback(() => {
    setBackground((prev) => {
      const filtered = colors.filter((p) => p !== prev);
      const newColor = filtered[Math.floor(Math.random() * filtered.length)];
      return newColor;
    });
    setActiveStage(0);
  }, []);

  // component configuration
  const disableLineup = useMemo(
    () => transitionStarted || atLowerRange || atHigherRange,
    [transitionStarted, atLowerRange, atHigherRange]
  );

  // useEffect(() => {
  //   console.log("at start transitionStarted", transitionStarted);
  // }, [transitionStarted]);

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", ({ matches }) => {
        setConfig((prev) => ({
          ...prev,
          progressRadius: matches
            ? radiusByMedia.desktop
            : radiusByMedia.mobile,
        }));
      });
  }, []);

  const value = useMemo(
    () => ({
      sceneConfig,
      activeModel: model,
      activeChapter: chapter,
      activeStage,
      setActiveStage,
      goToNext,
      disableInteraction,
      setDisableInteraction,
      atLowerRange,
      setAtLowerRange,
      atHigherRange,
      setAtHighRange,
      disableLineup,
      transitionStarted,
      setTransitionStarted,
      setConfig,
      animationProgress,
      setAnimationProgress,
    }),
    [
      sceneConfig,
      chapter,
      activeStage,
      goToNext,
      model,
      disableInteraction,
      atLowerRange,
      atHigherRange,
      disableLineup,
      transitionStarted,
      animationProgress,
    ]
  );

  return (
    <SceneContext.Provider value={value}>{children}</SceneContext.Provider>
  );
};

export const useScene = () => React.useContext(SceneContext);
