import * as THREE from "three";
import { shaderMaterial } from "@react-three/drei";
import glsl from "babel-plugin-glsl/macro";

const GrainShaderMaterial = shaderMaterial(
  {
    uColor: new THREE.Color(0x51b1f5),
    uLightPos: new THREE.Vector3(0, 5, 3),
    uLightColor: new THREE.Color(0xffffff),
    uLightIntensity: 0.8,
    uNoiseScale: 0.8,
    uLightAmplifier: 5,
    uNoiseVariant: 0.5,
    uTime: 0,
    uOpacity: 1,
    uBW: 0,
  },
  glsl`
      uniform vec3 uLightPos;
      uniform float uTime;
  
      varying vec3 vNormal;
      varying vec3 vSurfaceToLight;
  
      varying vec2 vUv;
      #pragma glslify: snoise3 = require(glsl-noise/simplex/3d);
  
      void main() {
  
          vNormal = normalize(normalMatrix * normal);
          vec3 pos = position;
  
          gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
  
          vec3 surfaceToLightDirection = vec3(modelViewMatrix * vec4(position, 1.0));
          vec3 worldLightPos = vec3(viewMatrix * vec4(uLightPos, 1.0));
          vSurfaceToLight = normalize(worldLightPos - surfaceToLightDirection);
          vUv = uv;
      }
  `,
  glsl`
      uniform vec3 uLightColor;
      uniform vec3 uColor;
      uniform float uLightIntensity;
      uniform float uNoiseScale;
      uniform float uLightAmplifier;
      uniform float uNoiseVariant;
  
      varying vec3 vNormal;
      varying vec3 vSurfaceToLight;
      varying vec2 vUv;
      uniform float uTime;
      uniform float uOpacity;
      uniform float uBW;

      #pragma glslify: snoise2 = require(glsl-noise/simplex/2d);
  
      vec3 light_reflection(vec3 lightColor) {
          vec3 ambient = lightColor;
  
          vec3 diffuse = lightColor * max(dot(vSurfaceToLight, vNormal), 0.0);
  
          return (ambient + diffuse);
      }
  
      void main() {
          vec3 light_value = light_reflection(uLightColor);
          light_value *= uLightIntensity;
  
          vec2 uv = gl_FragCoord.xy;
          uv /= uNoiseScale ;
  
          vec2 noiseFactor = uv * .2 + uTime * 0.2;
          uv *= snoise2(uv + uTime*0.002);
          vec3 colorNoise = vec3(snoise2(uv) * 0.5 + uNoiseVariant);
          colorNoise *= pow(light_value.r, uLightAmplifier);
  
          vec3 grainColor = vec3(
            max(colorNoise.r, uColor.r),
            max(colorNoise.g, uColor.g),
            max(colorNoise.b, uColor.b));

            // gl_FragColor = vec4(grainColor, uOpacity);


              vec3 greyScale = vec3(.3,.3,.3);
          
          
            vec3 mixedColor = vec3(mix(grainColor.rgb, vec3(dot(grainColor.rgb, greyScale)), uBW));
          gl_FragColor = vec4(mixedColor, uOpacity);
          


      }
  `
);
export default GrainShaderMaterial;
